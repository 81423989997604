@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'fontawesome-all.min.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

/*
	Eventually by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

// Breakpoints.

	@include breakpoints((
		xlarge:   ( 1281px,  1680px ),
		large:    ( 981px,   1280px ),
		medium:   ( 737px,   980px  ),
		small:    ( 481px,   736px  ),
		xsmall:   ( 361px,   480px  ),
		xxsmall:  ( null,    360px  ),
		short:    '(max-height: 640px)'
	));

// Base.

	@import 'base/reset';
	@import 'base/page';
	@import 'base/bg';
	@import 'base/typography';

// Components.

	@import 'components/section';
	@import 'components/icon';
	@import 'components/list';
	@import 'components/icons';
	@import 'components/form';
	@import 'components/button';

// Layout.

	@import 'layout/header';
	@import 'layout/signup-form';
	@import 'layout/footer';

// Custom.

	@import 'custom'